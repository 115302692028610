<template>
  <div class="show-detail">
    <div class="content">
      <div class="d-r" v-if="$store.state.isWap">
        <div class="title anime">{{ detail.worksName }}</div>
        <div class="b anime">
          <p>
            <i class="iconfont icon-liulan"></i>
            {{ detail.viewCount || 0 }}
          </p>
          <p class="pointer" @click="hits">
            <i class="iconfont icon-dianzan"></i>
            {{ detail.hits || 0 }}
          </p>
          <p class="pointer" @click="goComment">
            <i class="iconfont icon-pinglun"></i>
            {{ detail.messageCount || 0 }}
          </p>
          <div class="b-btn">
            <el-button type="primary" size="mini" @click="vote" :loading="btnLoad">给他投票({{ detail.ticket }})</el-button>
          </div>
        </div>
        <div class="text anime" v-html="detail.worksDescription"></div>
        <div class="share anime" v-if="false">
          <h3>分享至：</h3>
          <share
            :url="`https://www.huixianggongye.com/competition/showDetail?id=${detail.id}`"
            :image="detail.firstImage"
            :title="detail.worksName"
            :desc="detail.decoration"
            :top="false"
            :id="detail.id"
          />
        </div>
      </div>
      <div class="d-l">
        <div class="title" v-if="listVedio.length">视频作品</div>
        <div class="item anime" v-for="item in listVedio" :key="item.id">
          <video :src="item.fileUrl" controls playsinline preload="auto" x5-playsinline webkit-playsinline controlslist="nodownload"></video>
        </div>

        <div class="title" v-if="showList.length">作品图片</div>
        <div class="item anime" v-for="item in showList" :key="item.id">
          <el-image :id="item.id" :src="item.fileUrl" :preview-src-list="srcList(item)" fit="none" lazy></el-image>
          <p @click="showItem(item)">
            <i class="iconfont icon-fangda"></i>
          </p>
        </div>
        <div class="title" v-if="listMoXingImages.length">手板图片</div>
        <div class="item anime" v-for="item in listMoXingImages" :key="item.id">
          <el-image :id="item.id" :src="item.fileUrl" :preview-src-list="srcMoXingList(item)" fit="none" lazy></el-image>
          <p @click="showItem(item)">
            <i class="iconfont icon-fangda"></i>
          </p>
        </div>

        <div class="anime">
          <comment />
        </div>
      </div>
      <div class="d-r" v-if="!$store.state.isWap">
        <div class="title anime">{{ detail.worksName }}</div>
        <div class="b anime">
          <p>
            <i class="iconfont icon-liulan"></i>
            {{ detail.viewCount || 0 }}
          </p>
          <p class="pointer" @click="hits">
            <i class="iconfont icon-dianzan"></i>
            {{ detail.hits || 0 }}
          </p>
          <p class="pointer" @click="goComment">
            <i class="iconfont icon-pinglun"></i>
            {{ detail.messageCount || 0 }}
          </p>
          <div class="b-btn">
            <el-button type="primary" size="mini" @click="vote" :loading="btnLoad">给他投票({{ detail.ticket }})</el-button>
          </div>
        </div>
        <div class="text anime" v-html="detail.worksDescription"></div>
        <div class="share anime" v-if="false">
          <h3>分享至：</h3>
          <share
            :url="`https://www.huixianggongye.com/competition/showDetail?id=${detail.id}`"
            :image="detail.firstImage"
            :title="detail.worksName"
            :desc="detail.decoration"
            :top="false"
            :id="detail.id"
          />
        </div>
        <br />
        <div v-if="detail.isShowCard=='1'" class="item anime" v-for="item in listZiZhiImages" :key="item.id">
          <el-image :id="item.id" :src="item.fileUrl" :preview-src-list="srcZiZhiList(item)" fit="contain" lazy></el-image>
          <hr />
        </div>
        <div class="btns" v-if="isTeacher">
          <el-button type="primary" @click="dialogVisible=true" style="width: 100%">评委打分</el-button>
        </div>
      </div>
      <!-- 评委老师打分表 -->
      <el-dialog :visible.sync="dialogVisible" class="marking-dialog" center width="1000px">
        <div class="table">
          <div class="title">评委老师打分表</div>
          <div class="table-main">
            <table cellspacing="0">
              <tr>
                <th>类别</th>
                <th>满分</th>
                <th>票数</th>
                <th>得分</th>
              </tr>
              <tr>
                <td>线上投票</td>
                <td>0（0%）</td>
                <td>{{ detail.voteNum }}</td>
                <td>{{ detail.voteAutoScore }}</td>
              </tr>
              <tr>
                <td>评委打分</td>
                <td>100（100%）</td>
                <td>/</td>
                <td>{{ detail.teacherScore }}</td>
              </tr>
              <tr>
                <td>
                  <span class="red">总分</span>
                </td>
                <td>/</td>
                <td>/</td>
                <td>
                  <b class="red">{{ detail.totalScore }}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="desc">
          <div class="title">说明</div>
          <div class="desc-main">{{voteShuoming}}</div>
        </div>
        <div class="desc">
          <div class="title">评选标准</div>
          <div class="desc-main">{{voteBiaozhun}}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible2 = true">评委打分</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="dialogVisible2" class="marking-dialog" title="评委打分" center width="600px">
        <el-form :model="scoreForm" ref="ruleForm" label-width="60px" class="demo-ruleForm">
          <el-form-item label="分数" :rules="[
              { required: true, message: '请输入分数', trigger: 'blur' },
            ]" prop="teacherScore">
            <el-input-number v-model="scoreForm.teacherScore" :min="0" :max="teacherScoreRate" label="分数"></el-input-number>
            <span style="margin-left: 10px">最高{{teacherScoreRate}}分，最低0分</span>
          </el-form-item>
          <el-form-item label="评论" prop="teacherComments">
            <el-input v-model="scoreForm.teacherComments" type="textarea" rows="8" placeholder="请输入评论" maxlength="500" :show-word-limit="true"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" :loading="scoreLoad" @click="scoreSubmit">提交评分</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import comment from "./components/comment";
import share from "@/components/share";
export default {
  components: { share, comment },
  created() {
    this.stopF5Refresh();
  },
  data() {
    return {
      showList: [],
      listZiZhiImages: [],
      listMoXingImages: [],
      detail: {},
      btnLoad: false,
      listVedio: [],
      dialogVisible: false,
      dialogVisible2: false,
      scoreLoad: false,
      scoreForm: {
        teacherScore: "",
        teacherComments: "",
        id: ""
      },
      voteShuoming: "",
      voteBiaozhun: "",
      teacherScoreRate: 100
    };
  },
  computed: {
    userInfo() {
      console.log(this.$store.state.userInfo);
      return this.$store.state.userInfo;
    },
    isTeacher() {
      return (
        this.$store.state.userInfo.roleCode &&
        this.$store.state.userInfo.roleCode.indexOf("pingwei_teacher") >= 0
      );
    }
  },
  methods: {
    scoreSubmit() {
      this.scoreLoad = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$api
            .post(
              "/api/v1/saveByTeacher",
              this.$common.getFormData(this.scoreForm)
            )
            .then(res => {
              this.scoreLoad = false;
              if (res.code == 1) {
                this.$message.success("打分成功！");
                this.dialogVisible2 = false;
                this.getData();
              } else {
                this.$message.error(res.message);
              }
            });
        } else {
          this.scoreLoad = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    stopF5Refresh() {
      document.onkeydown = function(e) {
        var evt = window.event || e;
        var code = evt.keyCode || evt.which;
        //屏蔽F1---F12
        if (code > 111 && code < 124) {
          if (evt.preventDefault) {
            evt.preventDefault();
          } else {
            evt.keyCode = 0;
            evt.returnValue = false;
          }
        }
      };
      //禁止鼠标右键菜单
      document.oncontextmenu = function(e) {
        return false;
      };
      //阻止后退的所有动作，包括 键盘、鼠标手势等产生的后退动作。
      history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", function() {
        history.pushState(null, null, window.location.href);
      });
    },
    srcList(item) {
      let before = [];
      let after = [];
      let is = false;
      this.showList.forEach(items => {
        if (items.id == item.id) {
          is = true;
        }
        if (!is) {
          before.push(items.fileUrl);
        }
        if (is) {
          after.push(items.fileUrl);
        }
      });
      return [item.fileUrl, ...after, ...before];
    },
    srcZiZhiList(item) {
      let before = [];
      let after = [];
      let is = false;
      this.listZiZhiImages.forEach(items => {
        if (items.id == item.id) {
          is = true;
        }
        if (!is) {
          before.push(items.fileUrl);
        }
        if (is) {
          after.push(items.fileUrl);
        }
      });
      return [item.fileUrl, ...after, ...before];
    },
    srcMoXingList(item) {
      let before = [];
      let after = [];
      let is = false;
      this.listMoXingImages.forEach(items => {
        if (items.id == item.id) {
          is = true;
        }
        if (!is) {
          before.push(items.fileUrl);
        }
        if (is) {
          after.push(items.fileUrl);
        }
      });
      return [item.fileUrl, ...after, ...before];
    },
    showItem(item) {
      $("#" + item.id).click();
    },
    goComment() {
      $("body,html").animate(
        { scrollTop: $(".comment").offset().top - 150 },
        300
      );
    },
    hits() {
      this.$api
        .post("/api/v1/updateStudentHitsNumAddOne-" + this.detail.id)
        .then(res => {
          if (res.code == 1) {
            this.detail.hits++;
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500
            });
          }
        });
    },
    vote() {
      this.btnLoad = true;
      this.$api
        .post(
          `/api/v1/vote-${this.detail.id}`,
          this.$common.getFormData({
            id: this.detail.id
          })
        )
        .then(res => {
          this.btnLoad = false;
          if (res.code == 1) {
            this.detail = res.data.yzStudentInfo;
          }
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500
          });
        })
        .catch(err => {
          this.btnLoad = false;
        });
    },
    getData() {
      this.scoreForm.id = this.$route.query.id;
      this.$api
        .post(`/api/v1/getStudentInfoDetailById?id=${this.$route.query.id}`)
        .then(res => {
          this.showList = res.data.listImages || [];
          this.listVedio = res.data.listVedio || [];
          this.listZiZhiImages = res.data.listZiZhiImages || [];
          this.listMoXingImages = res.data.listImageFiles || [];
          // res.copyrightContent =
          //   res.data.copyrightContent &&
          //   res.data.copyrightContent.replaceAll("http://", "https://");
          this.$api
            .post("/api/v1/updateVoteViewCount-" + this.$route.query.id)
            .then(res => console.log(res));
          this.detail = res.data;
          this.$nextTick(() => {
            this.$store.commit("anime");
          });
        });
    }
  },
  mounted() {
    this.getData();
    this.$parent.finish ? this.$parent.finish() : null;
    this.$api.get("/api/v1/getYzVoteDefaultSetting").then(res => {
      if (res) {
        //voteShuoming  voteBiaozhun  voteXieyi
        this.voteShuoming = res.data.voteShuoming;
        this.voteBiaozhun = res.data.voteBiaozhun;
        this.teacherScoreRate = Number(
          (Number(res.data.teacherScoreRate) * 100).toFixed(0)
        );
      }
    });
  }
};
</script>
<style lang="scss">
.marking-dialog {
  .table {
    .title {
      background-color: $c2;
      text-align: center;
      color: white;
      line-height: 58px;
      font-size: 24px;
    }
    .table-main {
      table {
        width: 100%;
        border-color: $c2;
        border-top: 1px solid $c2;
        border-left: 1px solid $c2;
        color: #333333;
        tr:nth-child(odd) {
          background-color: #daebff;
        }
        th,
        td {
          width: 25%;
          text-align: center;
          padding: 14px 0;
          font-size: 18px;
          border-right: 1px solid $c2;
          border-bottom: 1px solid $c2;
          .red {
            color: red;
          }
        }
      }
    }
  }
  .desc {
    .title {
      line-height: 44px;
      background-color: #daebff;
      border: 1px solid $c2;
      border-top: 0;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #333333;
    }
    .desc-main {
      padding: 15px;
      font-size: 16px;
      line-height: 30px;
      border: 1px solid $c2;
      border-top: 0;
    }
  }
  .el-button {
    padding: 12px 50px;
  }
}
video::-webkit-media-controls {
  overflow: hidden !important;
}
video::-webkit-media-controls-enclosure {
  width: calc(100% + 32px);
  margin-left: auto;
}
.show-detail {
  padding: 4vw 0 0;
  .content {
    display: flex;
    justify-content: space-between;
    .d-l {
      width: 68%;
      .title {
        font-size: 24px;
        color: #f5f5f5;
        text-align: center;
        margin-bottom: 30px;
      }
      .item {
        margin-bottom: 30px;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        &:hover {
          p {
            opacity: 1;
          }
        }
        .el-image {
          border-radius: 20px;
          display: block;
          img {
            top: 0 !important;
            left: 0;
            transform: translate(0, 0) !important;
          }
        }
        video {
          width: 100%;
        }
        p {
          transition: 0.3s all;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 9;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.4);
          cursor: pointer;
          i {
            font-size: 24px;
          }
        }
      }
    }
    .d-r {
      width: 28%;
      color: #f5f5f5;
      .title {
        font-size: 24px;
        line-height: 40px;
      }
      .btns {
        margin-top: 30px;
      }
      .b {
        display: flex;
        margin-top: 30px;
        p {
          width: 20%;
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 20px;
          &.pointer {
            cursor: pointer;
            &:hover {
              color: $c2;
            }
          }
          i {
            margin-right: 10px;
          }
        }
        .b-btn {
          width: 40%;
          text-align: right;
          .el-button {
          }
        }
        padding-bottom: 20px;
        border-bottom: 1px solid #666666;
      }
      .text {
        margin-top: 20px;
        min-height: 20vh;
        img {
          max-width: 100%;
          height: auto !important;
        }
        padding-bottom: 20px;
      }
      & > .share {
        margin-top: 30px;
        h3 {
          font-size: 20px;
          font-weight: 500;
        }
        .share {
          margin-top: 20px;
        }
      }
      .btn {
        margin-top: 80px;
        text-align: center;
      }
    }
  }
}
@media (max-width: 1000px) {
  .show-detail {
    padding: 4vw 0 0;
    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .d-l {
        width: 100%;
        .title {
          font-size: 5vw;
          color: #f5f5f5;
          text-align: center;
          margin: 5vw 0;
        }
        .item {
          margin-bottom: 5vw;
          position: relative;
          border-radius: 2vw;
          overflow: hidden;
          &:hover {
            p {
              opacity: 1;
            }
          }
          .el-image {
            border-radius: 2vw;
            display: block;
            img {
              top: 0 !important;
              left: 0;
              transform: translate(0, 0) !important;
            }
          }
          video {
            width: 100%;
          }
          p {
            transition: 0.3s all;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 9;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.4);
            cursor: pointer;
            i {
              font-size: 5vw;
            }
          }
        }
      }
      .d-r {
        width: 100%;
        color: #f5f5f5;
        .title {
          font-size: 5vw;
          line-height: 6vw;
        }
        .b {
          display: flex;
          margin-top: 2vw;
          p {
            width: 20%;
            display: flex;
            align-items: center;
            font-size: 4vw;
            line-height: 6vw;
            &.pointer {
              cursor: pointer;
              &:hover {
                color: $c2;
              }
            }
            i {
              margin-right: 1vw;
            }
          }
          .b-btn {
            width: 40%;
            text-align: right;
            .el-button {
            }
          }
          padding-bottom: 2vw;
          border-bottom: 1px solid #666666;
        }
        .text {
          margin-top: 2vw;
          min-height: 50vw;
          * {
            font-size: 3.5vw !important;
          }
          img {
            max-width: 100%;
            height: auto !important;
          }
          padding-bottom: 2vw;
        }
        & > .share {
          margin-top: 2vw;
          h3 {
            font-size: 4vw;
            font-weight: 500;
          }
          .share {
            margin-top: 4vw;
          }
        }
        .btn {
          margin-top: 5vw;
          text-align: center;
        }
      }
    }
  }
}
</style>